import { ServicePlate } from '@/pages/exchange/integration/services/types';
import { SmsOperator } from '@/hooks/useCompanies';
import { NotificationOperator, NotificationSendType } from '@/hooks/useCompanySettingsSecure';
import { Ref } from 'vue';
import { IntegrationDialog } from '@/pages/exchange/integration/dialogs/integrationDialogs';
import { IMAP_OPERATOR_UUID } from '@/pages/exchange/integration/dialogs/IntegrationImap.vue';

export type NotificationService =
  ServicePlate & {
  uuid?: SmsOperator;
  key: NotificationOperator;
  types: NotificationSendType[];
}

export function useGetNotificationServices(companyId: Ref<number>) {
  return () => [
    {
      label: 'SendPulse',
      key: 'sendpulse',
      logo: require('@/assets/images/sendpulse.svg'),
      component: IntegrationDialog.integrationSendPulse,
      types: ['sms', 'viber'],
      addInRoute: false,
      payload: {
        companyId: companyId.value,
      },
      uuid: SmsOperator.Sendpulse,
    }, {
      label: 'SMS Центр',
      key: 'smsc',
      logo: require('@/assets/images/smsc.png'),
      component: IntegrationDialog.integrationSmsC,
      types: ['sms', 'viber', 'email'],
      addInRoute: false,
      payload: {
        companyId: companyId.value,
      },
      uuid: SmsOperator.SmsC,
    }, {
      label: 'Imobis',
      key: 'imobis',
      logo: require('@/assets/images/imobis.png'),
      component: IntegrationDialog.integrationImobis,
      types: ['sms'],
      addInRoute: false,
      payload: {
        companyId: companyId.value,
      },
      uuid: SmsOperator.Imobis,
    },
    {
      label: 'Юрробот',
      key: 'kvint',
      types: ['voice'],
      logo: require('@/components/logo/assets/darkLogo.svg'),
      component: IntegrationDialog.integrationKvint,
      payload: {
        companyId: companyId.value,
      },
      uuid: '254f5043-452a-46f1-b9c5-ebabde89ae4b',
    },
    {
      label: 'IMAP',
      key: 'imap',
      types: ['email'],
      logo: '',
      component: IntegrationDialog.integrationImap,
      payload: {
        companyId: companyId.value,
      },
      uuid: IMAP_OPERATOR_UUID,
    },
    {
      label: 'SMS Traffic',
      key: 'smstraffic',
      logo: require('@/assets/images/smstraffic.svg'),
      component: IntegrationDialog.integrationSmsTraffic,
      types: ['sms'],
      addInRoute: false,
      payload: {
        companyId: companyId.value,
      },
      uuid: SmsOperator.SmsTraffic,
    },
    {
      label: 'Stream Telecom',
      key: 'stream_telecom',
      logo: require('@/assets/images/streamtelecom.png'),
      component: IntegrationDialog.integrationStreamTelecom,
      types: ['sms'],
      addInRoute: false,
      payload: {
        companyId: companyId.value,
      },
      uuid: SmsOperator.StreamTelecom,
    },
  ];
}
