import { Ref, ref } from 'vue';
import { PermissionsModel, useCompanyPermissionsApi } from '@/hooks/useCompanyPermissionsApi';
import { ProductionType } from '@/hooks/useConstructor';
import { EmployeeRole, useEmployees } from '@/hooks/useEmployees';
import { PermissionType } from '@/hooks/useDefaultCompanyPermissions';
import { useUser } from '@/hooks/useUser';

export function usePermissionsMap(company: Ref<number | null>) {

  const companyPermissionsApi = useCompanyPermissionsApi(company as Ref<number>);
  const { fetchEmployee } = useEmployees();
  const { user } = useUser();

  const permissionsMap = ref<PermissionsModel>({
    [ProductionType.pretrial]: null,
    [ProductionType.judicial]: null,
    [ProductionType.executive]: null,
  });

  const fetchPermissions = async () => {
    {
      const { status, response } = await fetchEmployee({
        id: user.value?.id as number,
        companyId: company.value as number,
      });
      if (status) {
        if (response.is_owner
          || response.employee_role === EmployeeRole.manager
          || response.employee_role === EmployeeRole.owner
        ) {
          permissionsMap.value = {
            [PermissionType.pretrial]: 'write',
            [PermissionType.judicial]: 'write',
            [PermissionType.executive]: 'write',
            [PermissionType.general_info]: 'write',
            [PermissionType.data_exchange_integrations]: 'write',
            [PermissionType.data_exchange_upload_report]: 'write',
            [PermissionType.rates_and_services]: 'write',
            [PermissionType.billing_and_transactions]: 'write',
          };
          return;
        }
      }
    }
    const { status, response } = await companyPermissionsApi.fetchUserPermissionsMap(
      user.value?.id as number,
    );
    if (status) {
      permissionsMap.value = response;
    }
  };

  return { fetchPermissions, permissionsMap };
}
